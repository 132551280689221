.section {
    &.hero {
        padding: 170px 0 240px;
        overflow: hidden;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            padding: 360px 0 320px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
    }
}

.main-title {
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        width: 1050px;
        height: 770px;
        position: absolute;
        top: -290px;
        left: -510px;
        z-index: -1;
        background: url('../img/rings-hero.svg') top left / 100% auto no-repeat;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
                width: 1403px;
                height: 1090px;
                top: -349px;
                left: -590px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
            width: 1382px;
            height: 1010px;
            top: -400px;
            left: -260px;
        }
    }
    .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            width: 60px;
            height: 60px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
        &::before {
            -webkit-text-fill-color: initial;
        }
        &.hello  {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $orange;
            img {
                max-width: 24px;
                max-height: 24px;
                position: relative;
                transform-origin: bottom right;
                // animation: name duration timing-function delay iteration-count direction fill-mode;
                animation: hello 1s infinite alternate both;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    max-width: 36px;
                    max-height: 36px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
        }
        &.smile  {
            &::before {
                font-size: 40px;
                color: $yellow;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    font-size: 50px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
        }
        &.pallet  {
            background-color: $yellow;
            &::before {
                font-size:30px;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    font-size:44px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
        }
        &.app  {
            background-color: $purple;
            &::before {
                font-size: 24px;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    font-size: 36px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
        }
        &.icon-arrow-right {
            position: relative;
            top: 3px;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                top: 7px;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            &::before {
                font-size: 32px;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    font-size: 56px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
            &.white {
                &::before {
                    color: #fff;
                }
            }
            &.orange {
                &::before {
                    color: $orange;
                }
            }
        }
    }
}

@keyframes hello {
    0% {
        transform: rotate(-5deg);
    }
    10% {
        transform: rotate(5deg);
    }
    20% {
        transform: rotate(-5deg);
    }
    30% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}
