$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$container-max-width-sm-min: 540px;
$container-max-width-md-min: 720px;
$container-max-width-lg-min: 960px;
$container-max-width-xl-min: 1200px;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: $screen-sm-min,
    md: $screen-md-min,
    lg: $screen-lg-min,
    xl: $screen-xl-min
) !default;

$yellow: #F6EC2C;
$orange: #F45D2D;
$purple: #9F02FF;
$grey: #696C74;
$red: #FF3135;
$blue: #0D99FF;
$green: #48F583;

$basecolor: #fff;
$selection: #241a17;
$linkcolor: #ee5340;

$blacktext: #000;
