html {
    scroll-behavior: smooth;
}

body  {
    @include font-inter(400, 16px, 1);
    background-color: #000;
}

.container {
    padding-right: 25px;
    padding-left: 25px;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        padding-right: 15px;
        padding-left: 15px;
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
}
