@mixin declare-font-face(
    $font-family,
    $font-filename,
    $font-weight: normal,
    $font-style: normal,
    $font-stretch: normal
) {
    @font-face {
        font-family: "#{$font-family}";
        src: url(#{$font-filename}.woff2) format("woff2"), url(#{$font-filename}.woff) format("woff");
        font-weight: $font-weight;
        font-style: $font-style;
        font-stretch: $font-stretch;
    }
}

@mixin size($width: auto, $height: auto) {
    height: $height;
    width: $width;
}

@mixin font-inter($weight: normal, $size: 14px, $lineHeight: 18px) {
    font-family: 'Inter', sans-serif;
    font-size: $size;
    font-weight: $weight;
    line-height: $lineHeight;
}

@mixin absolute-center($axis: "both") {
    position: absolute;
    @if $axis == "y" {
        top: 50%;
        transform: translateY(-50%);
    }
    @if $axis == "x" {
        left: 50%;
        transform: translateX(-50%);
    }
    @if $axis == "both" {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin placeholder($color: $grayLight) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &:-moz-placeholder {
        color: $color;
    }
}

@mixin clearfix() {
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

.sr-only {
    @include sr-only();
}
