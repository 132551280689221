// @include declare-font-face('Inter', '../fonts/Inter-Thin', 100);
// @include declare-font-face('Inter', '../fonts/Inter-ExtraLight', 200);
// @include declare-font-face('Inter', '../fonts/Inter-Light', 300);
@include declare-font-face('Inter', '../fonts/Inter-Regular', 400);
// @include declare-font-face('Inter', '../fonts/Inter-Medium', 500);
// @include declare-font-face('Inter', '../fonts/Inter-SemiBold', 600);
@include declare-font-face('Inter', '../fonts/Inter-Bold', 700);
// @include declare-font-face('Inter', '../fonts/Inter-ExtraBold', 800);
// @include declare-font-face('Inter', '../fonts/Inter-Black', 900);

@include declare-font-face('Icomoon', '../fonts/icomoon', 400);
