body {
    &.card-solo {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0A0A0A;
        .gradient {
            width: 258px;
            height: 292px;
            @include absolute-center();
            filter: blur(80px);
            .gradient-1,
            .gradient-2,
            .gradient-3 {
                border-radius: 50%;
                position: absolute;
            }
            .gradient-1 {
                width: 174px;
                height: 174px;
                background: $yellow;
                bottom: 0;
                left: 0;
            }
            .gradient-2 {
                width: 190px;
                height: 190px;
                background: $orange;
                top: 0;
                right: 0;
            }
            .gradient-3 {
                width: 156px;
                height: 156px;
                background: $purple;
                right: 0;
                bottom: 0;
            }
        }
    }
}

.section {
    &.card {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
