.section {
    &.skills {
        margin: 0 0 50px;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            margin: 0 0 190px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
    }
}

.card-skill {
    margin: 0 0 60px;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        margin: 0;
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
    .icon {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 55px;
        &::before {
            font-size: 44px;
        }
        &.icon-pallet {
            background-color: $yellow;
        }
        &.icon-grid {
            background-color: $orange;
        }
        &.icon-term {
            background-color: $purple;
        }
    }
    .card-skill-title {
        font-weight: 700;
        font-size: 24px;
        color: #fff;
        margin: 0 0 30px;
    }
    p {
        line-height: 26px;
        color: $grey;
    }
}
