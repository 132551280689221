.section {
    &.footer {
        padding: 50px 0 0;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            padding: 120px 0 0;
            background: url('../img/rings-footer.svg') top -31px left 230px / auto 700px no-repeat;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
        .avatar-selectors-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 50px;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                margin: 0 0 80px;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            .avatar-selectors {
                border-radius: 30px;
                padding: 4px;
                background-color: #121212;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                z-index: 1;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    border-radius: 50px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
                .avatar-selector {
                    &:not(:last-child) {
                        margin: 0 0 10px;
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            margin: 0 10px 0 0;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                    .input-container {
                        input {
                            display: none;
                            &:checked  {
                                &+label {
                                    background-color: #2B2B2B;
                                }
                            }
                        }
                        label {
                            width: 190px;
                            height: 50px;
                            padding: 5px;
                            border-radius: 50px;
                            background-color: transparent;
                            display: flex;
                            align-items: center;
                            transition: background-color .2s ease;
                            .avatar  {
                                margin: 0 13px 0 0;
                            }
                            .text {
                                @include font-inter(400, 14px, 1);
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .socials-container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 70px;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                margin: 0 0 100px;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            .socials {
                display: flex;
                align-items: center;
                .social {
                    margin: 0 13px 0 0;
                    display: none;
                    &.active {
                        display: block;
                        a:not([href="#"]) {
                            &.da {
                                &::before {
                                    color: $yellow;
                                }
                            }
                            &.front {
                                &::before {
                                    color: $orange;
                                }
                            }
                            &.back {
                                &::before {
                                    color: $purple;
                                }
                            }
                        }
                    }
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    a {
                        position: relative;
                        padding: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .text {
                            @include font-inter(400, 14px, 1);
                            color: #fff;
                            @include absolute-center(x);
                            bottom: 0;
                            opacity: 0;
                            transition: bottom .2s ease, opacity .2s ease;
                            pointer-events: none;
                        }
                        &::before {
                            font-size: 30px;
                            color: #404040;
                            transition: color .2s ease;
                        }
                        &[href="#"] {
                            pointer-events: none;
                        }
                        &:active,
                        &:focus,
                        &:hover {
                            &:not([href="#"]) {
                                &.da {
                                    &::before {
                                        color: $yellow;
                                    }
                                }
                                &.front {
                                    &::before {
                                        color: $orange;
                                    }
                                }
                                &.back {
                                    &::before {
                                        color: $purple;
                                    }
                                }
                                .text {
                                    bottom: -30px;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
