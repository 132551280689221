.header {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9001;
    .header-content {
        height: 70px;
        display: grid;
        grid-template-columns: 50% 50%;
        transition: height .2s ease, padding .2s ease;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            grid-template-columns: 30% 40% 30%;
            height: 150px;
            padding: 60px 0 0;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
        .logo-container {
            align-self: center;
            img {
                height: 36px;
            }
            #logo-laroutourne-studio {
                height: 36px;
                .balloon {
                    animation: logoAnim 5s infinite linear;
                }
                // .dot {
                //     animation: logoAnim 5s 2s infinite linear;
                // }
            }
        }
        .avatars-container  {
            width: 100%;
            height: 50px;
            position: relative;
            display: none;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                display: block;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            .avatar {
                position: absolute;
                top: 50%;
                transition: left .2s ease, transform .2s ease;
                will-change: left, transform;
                &:first-child {
                    left: calc(50% - 90px);
                    transform: translateY(-50%);
                }
                &:nth-child(2) {
                    left: 50%;
                    transform: translate(-50%, calc(-50% + 20px));
                    transition-delay: .05s;
                }
                &:nth-child(3) {
                    left: calc(50% + 40px);
                    transform: translateY(-50%);
                    transition-delay: .1s;
                }
            }
        }
        a {
            font-size: 14px;
            color: #fff;
            justify-self: end;
            // @include absolute-center(y);
            // right: 30px;
            align-self: center;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                // align-self: auto;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
        }
    }
    &.fixed {
        backdrop-filter: blur(33px);
        background-color: rgba(12, 12, 12, 0.72);
        border-bottom: 1px solid;
        border-image: linear-gradient(147.63deg, #898989 -5.85%, rgba(34, 34, 34, 0.72) 80.16%) 1;
        .header-content {
            height: 72px;
            padding: 11px 0 11px;
            .logo-container {
                align-self: center;
            }
        }
        .avatar {
            // transform: translateY(-50%) !important;
            // &:first-child {
            //     left: 0;
            // }
            &:nth-child(2) {
                transform: translate(-50%, -50%) !important;
                // left: 32px;
            }
            // &:nth-child(3) {
            //     left: 64px;
            // }
            pointer-events: none;
        }
        a {
            align-self: center;
        }
    }
}

@keyframes logoAnim {
    0% {
        fill: $yellow;
    }
    33% {
        fill: $orange;
    }
    66% {
        fill: $purple;
    }
    100% {
        fill: $yellow;
    }
}
