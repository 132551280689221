.main-title {
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    background: linear-gradient(272.81deg, #f45d2d 14.15%, #ffffff 100%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        font-size: 56px;
        line-height: 80px;
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
    .text {
        white-space: nowrap;
    }
}

.section-title {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    margin: 0 0 60px;
    text-align: center;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        font-size: 60px;
        margin: 0 0 90px;
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
}

[class^='icon-'],
[class*=' icon-'] {
    &::before,
    &::after {
        font-family: 'Icomoon' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        pointer-events: none;
    }
}

.icon-laroutourne:before {
    content: '\e90d';
}
.icon-grid:before {
    content: '\e90b';
}
.icon-term:before {
    content: '\e90c';
}
.icon-arrow-link:before {
    content: '\e900';
}
.icon-arrow-up:before {
    content: '\e901';
}
.icon-arrow-right:before {
    content: '\e90a';
}
.icon-codepen:before {
    content: '\e902';
}
.icon-dribbble:before {
    content: '\e903';
}
.icon-file:before {
    content: '\e904';
}
.icon-instagram:before {
    content: '\e905';
}
.icon-linkedin:before {
    content: '\e906';
}
.icon-pallet:before {
    content: '\e907';
}
.icon-smile:before {
    content: '\e908';
}
.icon-twitter:before {
    content: '\e909';
}
