.section {
    &.contact {
        // margin: 0 0 120px;
        .contact-form-container {
            border-radius: 20px;
            padding: 50px 20px 40px;
            background-color: $yellow;
            backdrop-filter: blur(33px);
            opacity: 1;
            transition: opacity .2s ease;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                padding: 100px 0 60px;
                background: $yellow url('../img/rings-contact.svg') top -90px right 92px / auto 750px no-repeat;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            &.loading-please-wait {
                pointer-events: none;
                opacity: .3;
            }
            .contact-form-title {
                font-weight: 700;
                font-size: 35px;
                line-height: 44px;
                margin: 0 0 23px;
                @include media-breakpoint-up(sm) {
                }
                @include media-breakpoint-up(md) {
                    font-size: 60px;
                    line-height: 72px;
                    margin: 0 0 40px;
                }
                @include media-breakpoint-up(lg) {
                }
                @include media-breakpoint-up(xl) {
                }
            }
            .field {
                .message {
                    display: none;
                }
                &.error {
                    .message {
                        display: block;
                    }
                }
            }
            .input-container {
                position: relative;
                margin: 0 0 10px;
                &.input {
                    min-height: 60px;
                    border-radius: 60px;
                    background-color: #DFD61E;
                    transition: background-color .2s ease;
                    @include media-breakpoint-up(sm) {
                    }
                    @include media-breakpoint-up(md) {
                        min-height: 80px;
                        border-radius: 80px;
                    }
                    @include media-breakpoint-up(lg) {
                    }
                    @include media-breakpoint-up(xl) {
                    }
                    &.focus {
                        background-color: #CCC205;
                        .label {
                            font-size: 12px;
                            color: #FFF300;
                            top: 6px;
                            @include media-breakpoint-up(sm) {
                            }
                            @include media-breakpoint-up(md) {
                                font-size: 14px;
                                top: 10px;
                            }
                            @include media-breakpoint-up(lg) {
                            }
                            @include media-breakpoint-up(xl) {
                            }
                        }
                    }
                    &.textarea {
                        border-radius: 30px;
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            border-radius: 40px;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                }
                &.submit {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    @include media-breakpoint-up(sm) {
                    }
                    @include media-breakpoint-up(md) {
                        width: 60px;
                        height: 60px;
                    }
                    @include media-breakpoint-up(lg) {
                    }
                    @include media-breakpoint-up(xl) {
                    }
                    &::before  {
                        font-size: 16px;
                        color: #fff;
                        @include absolute-center();
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            font-size: 24px;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                }
                input {
                    border: 0;
                    height: 60px;
                    background-color: transparent;
                    @include media-breakpoint-up(sm) {
                    }
                    @include media-breakpoint-up(md) {
                        height: 80px;
                    }
                    @include media-breakpoint-up(lg) {
                    }
                    @include media-breakpoint-up(xl) {
                    }
                    &[type="email"] {
                        width: 100%;
                        @include font-inter(400, 16px, normal);
                        padding: 0 20px;
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            font-size: 18px;
                            line-height: 1;
                            padding: 0 30px;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                    &[type="submit"] {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #000;
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            width: 60px;
                            height: 60px;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                }
                textarea {
                    width: 100%;
                    height: 60px;
                    @include font-inter(400, 16px, normal);
                    border: 0;
                    padding: 27px 100px 20px 20px;
                    background-color: transparent;
                    resize: none;
                    transition: height .2s ease;
                    @include media-breakpoint-up(sm) {
                    }
                    @include media-breakpoint-up(md) {
                        height: 80px;
                        font-size: 18px;
                        line-height: 1.5;
                        padding: 27px 100px 20px 30px;
                    }
                    @include media-breakpoint-up(lg) {
                    }
                    @include media-breakpoint-up(xl) {
                    }
                }
                .label {
                    font-size: 16px;
                    color: rgba(#000, .36);
                    pointer-events: none;
                    position: absolute;
                    top: 21px;
                    left: 20px;
                    transition: font-size .2s ease, color .2s ease, top .2s ease;
                    @include media-breakpoint-up(sm) {
                    }
                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                        top: 32px;
                        left: 30px;
                    }
                    @include media-breakpoint-up(lg) {
                    }
                    @include media-breakpoint-up(xl) {
                    }
                }
            }
        }
    }
}
