.section {
    &.cards {
        margin: -110px 0 70px;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            margin: -200px 0 230px;
        }
        @include media-breakpoint-up(lg) {
            margin: -110px 0 230px;
        }
        @include media-breakpoint-up(xl) {
        }
    }
}

.cards-container {
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
    }
    @include media-breakpoint-up(xl) {
    }
}

.card-container {
    width: 100%;
    max-width: 326px;
    position: relative;
    margin: 0 auto;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        display: block;
        justify-content: normal;
    }
    @include media-breakpoint-up(lg) {
        margin: 0;
    }
    @include media-breakpoint-up(xl) {
        max-width: 346px;
    }
    &:not(:last-child) {
        margin-bottom: 20px;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
        @include media-breakpoint-up(xl) {
        }
    }
    &.da {
        z-index: 10;
    }
    &.front {
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: -30px;
            left: 300px;
            transform: rotate(15deg);
            z-index: 9;
        }
        @include media-breakpoint-up(xl) {
        }
    }
    &.back {
        z-index: 8;
    }
    &.solo {
        &.front {
            position: inherit;
            transform: rotate(0);
        }
    }
    .shadow {
        width: 100%;
        // max-width: 346px;
        // height: 418px;
        height: 18px;
        // border: 1px solid rgba(#898989, .3);
        // border-radius: 20px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .2s ease, bottom .3s ease;
        will-change: opacity, bottom;
        // transition: bottom .3s ease;
        pointer-events: none;
        background: url('../img/card-person-shadow.svg') center / 100% auto no-repeat;
        display: none;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
        }
        @include media-breakpoint-up(lg) {
            display: block;
        }
        @include media-breakpoint-up(xl) {
            // max-width: 346px;
        }
        &.shadow-1 {
            z-index: -1;
            // transition: opacity .2s ease, bottom .5s ease;
        }
        &.shadow-2 {
            z-index: -2;
            // transition-delay: .1s;
            // transition: opacity .2s ease, bottom .2s ease;
        }
        &.shadow-3 {
            z-index: -3;
            // transition-delay: .2s;
            // transition: opacity .2s ease, bottom .2s ease;
        }
        &.shadow-4 {
            z-index: -4;
            // transition-delay: .4s;
            // transition: opacity .2s ease, bottom .2s ease;
        }
        &.shadow-5 {
            z-index: -5;
            // transition-delay: .6s;
            // transition: opacity .2s ease, bottom .2s ease;
        }
        &.shadow-6 {
            z-index: -6;
            // transition-delay: .7s;
            // transition: opacity .2s ease, bottom .2s ease;
        }
    }
    &.hover {
        .shadow {
            // opacity: 1;
            &.shadow-1 {
                bottom: -35px;
                opacity: .8;
            }
            &.shadow-2 {
                bottom: -55px;
                opacity: .8;
            }
            &.shadow-3 {
                bottom: -75px;
                opacity: .6;
            }
            &.shadow-4 {
                bottom: -88px;
                opacity: .6;
            }
            &.shadow-5 {
                bottom: -101px;
                opacity: .4;
            }
            &.shadow-6 {
                bottom: -114px;
                opacity: .4;
            }
        }
    }
}

.card-person {
    // flex: 0 1 326px;
    height: 418px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 20px;
    border: 1px solid rgba(#898989, .3);
    // border-image: linear-gradient(147.63deg, #898989 -5.85%, rgba(34, 34, 34, 0.72) 80%) 1;
    // background: linear-gradient(147.63deg, #898989 -5.85%, rgba(34, 34, 34, 0.72) 80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 46px 0 0;
    background-color: rgba(12, 12, 12, .72);
    backdrop-filter: blur(33px);
    // .card-person-content {
    //     width: 336px;
    //     height: 416px;
    //     border-radius: 20px;
    // }
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
        // width: 346px;
    }
    &::before {
        content: '';
        width: 44px;
        height: 140px;
        border-radius: 0 20px 20px 0;
        @include absolute-center(y);
        left: 0;
    }
    .avatar {
        margin: 0 0 25px;
    }
    .card-firstname {
        font-weight: 700;
        font-size: 18px;
        margin: 0 0 12px;
        background: linear-gradient(272.81deg, #F45D2D 14.15%, #F6FA2C 100%),
                    linear-gradient(0deg, #FFFFFF, #FFFFFF);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .card-job {
        font-size: 16px;
        color: #aaa;
    }
    .socials {
        margin: auto 0 46px;
        display: flex;
        .social {
            &:not(:last-child) {
                margin: 0 10px 0 0;
            }
            a {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                &::before {
                    font-size: 18px;
                    color: #7A7A7A;
                    transition: color .2s ease;
                }
                &.icon-laroutourne {
                    &::before {
                        margin-left: -13px;
                        margin-top: -3px;
                        font-size: 19px;
                    }
                }
            }
        }
    }
    &.da {
        &::before {
            background-color: $yellow;
        }
        a:hover::before {
            color: $yellow;
        }
    }
    &.front {
        &::before {
            background-color: $orange;
        }
        a:hover::before {
            color: $orange;
        }
    }
    &.back {
        &::before {
            background-color: $purple;
        }
        a:hover::before {
            color: $purple;
        }
    }
    &.solo {
        // width: 346px;
        border: 0;
        background-color: #0A0A0A;
        backdrop-filter: none;
        &::before {
            content: none;
        }
    }
}
