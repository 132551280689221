.section {
    &.references {
        margin: 0 0 20px;
        @include media-breakpoint-up(sm) {
        }
        @include media-breakpoint-up(md) {
            margin: 0 0 120px;
        }
        @include media-breakpoint-up(lg) {
        }
        @include media-breakpoint-up(xl) {
        }
        .card-reference {
            // height: 610px;
            max-width: 370px;
            margin: 0 auto 30px;
            border-radius: 20px;
            padding: 6px 6px 25px;
            background-color: #111;
            display: block;
            transition: background-color .2s ease;
            @include media-breakpoint-up(sm) {
            }
            @include media-breakpoint-up(md) {
                margin: 0 0 30px;
            }
            @include media-breakpoint-up(lg) {
            }
            @include media-breakpoint-up(xl) {
            }
            .img-container {
                height: 420px;
                border-radius: 20px;
                margin: 0 0 32px;
                position: relative;
                overflow: hidden;
                .screen-container {
                    width: 100%;
                    height: 420px;
                    opacity: .3;
                    transition: opacity .2s ease;
                    background-color: $orange;
                    position: relative;
                    overflow: hidden;
                    img {
                        width: 318px;
                        height: 380px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        @include media-breakpoint-up(sm) {
                        }
                        @include media-breakpoint-up(md) {
                            top: 40px;
                            right: auto;
                            bottom: auto;
                            left: 40px;
                        }
                        @include media-breakpoint-up(lg) {
                        }
                        @include media-breakpoint-up(xl) {
                        }
                    }
                }
                .icon-reference {
                    width: 50px;
                    height: 50px;
                    border-radius: 20px;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    background: #fff;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .text-container {
                padding: 0 20px;
                position: relative;
                .card-reference-title {
                    font-weight: 700;
                    font-size: 24px;
                    color: #fff;
                    margin: 0 0 10px;
                }
                .card-reference-skills {
                    margin: 0 0 30px;
                    border-radius: 4px;
                    padding: 9px 6px;
                    background-color: rgba($yellow, 0.24);
                    display: inline-flex;
                    align-items: center;
                    .card-reference-skill {
                        font-size: 14px;
                        color: $yellow;
                        position: relative;
                        &:not(:last-child) {
                            padding: 0 20px 0 0;
                            &::before {
                                content: '·';
                                color: currentColor;
                                @include absolute-center(y);
                                right: 8px;
                            }
                        }
                    }
                }
                span.icon-arrow-link {
                    position: absolute;
                    top: 30px;
                    right: 35px;
                    opacity: 0;
                    transition: top .2s ease, right .2s ease, opacity .2s ease;
                    &::before {
                        font-size: 20px;
                        color: #fff;
                    }
                }
            }
            &:active,
            &:focus,
            &:hover {
                background-color: #1f1f1f;
                .screen-container {
                    opacity: 1;
                }
                span.icon-arrow-link {
                    top: 20px;
                    right: 25px;
                    opacity: 1;
                }
            }
        }
        .col-md-6 {
            &:nth-child(6n+1) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $red;
                        }
                    }
                }
            }
            &:nth-child(6n+2) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $orange;
                        }
                    }
                }
            }
            &:nth-child(6n+3) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $purple;
                        }
                    }
                }
            }
            &:nth-child(6n+4) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $blue;
                        }
                    }
                }
            }
            &:nth-child(6n+5) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $green;
                        }
                    }
                }
            }
            &:nth-child(6n+6) {
                .card-reference {
                    .img-container {
                        .screen-container {
                            background-color: $yellow;
                        }
                    }
                }
            }
        }
    }
}
