.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
        content: '';
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        z-index: -1;
        pointer-events: none;
    }
    &.da {
        background-color: $yellow;
        &::before {
            border-color: $yellow;
        }
    }
    &.front {
        background-color: $orange;
        &::before {
            border-color: $orange;
        }
    }
    &.back {
        background-color: $purple;
        &::before {
            border-color: $purple;
        }
    }
    img {
        height: 50px;
    }
    .text {
        font-size: 12px;
        color: #fff;
        @include absolute-center(x);
        top: 100%;
        pointer-events: none;
        opacity: 0;
        transition: top .2s ease, opacity .2s ease;
    }
    .avatars-container & {
        &:hover {
            &::before {
                animation: pulse 1s infinite;
            }
            .text {
                top: calc(100% + 13px);
                opacity: 1;
            }
        }
    }
    .card-reference-avatars & {
        width: 30px;
        height: 30px;
        img {
            height: 26px;
        }
    }
    .card-person & {
        width: 90px;
        height: 90px;
        img {
            height: 64px;
        }
    }
    .avatar-selectors & {
        width: 40px;
        height: 40px;
        img {
            height: 38px;
        }
    }
}

.reveal {
    position: relative;
    opacity: 0;
    // transform: translateY(50px);
    transition: opacity 1s ease;//, transform 2s ease;
    &.show {
        opacity: 1;
        // transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(.8);
        opacity: 0;
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
}
